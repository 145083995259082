import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import {
  FaInstagram,
  FaWhatsapp,
  FaShoppingCart,
  FaShippingFast,
  FaHeadset,
  FaLock,
} from "react-icons/fa";
import "./App.css";
import logo from "./IMG/logo.png";
import banner from "./IMG/banner.png";
import banner2 from "./IMG/banner2.png";
import { db } from "./firebase";
import {
  collection,
  query,
  orderBy,
  getDocs,
  addDoc,
  Timestamp,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import Carrinho from "./Carrinho";
import Produto from "./Produto";
import Rodape from "./Rodape";
import Checkout from "./Checkout";
import Lupa from "./Lupa";
import Categorias from "./Categorias";
import TextoRotativo from './TextoRotativo'; 
import Pedidos from './Pedidos';
import AOS from "aos";
import "aos/dist/aos.css";
import 'lazysizes'; // Importa Lazysizes
import 'lazysizes/plugins/attrchange/ls.attrchange'


function App() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(db, "products"), orderBy("createdAt", "desc")); // Ordena pela data de criação
      const querySnapshot = await getDocs(q);
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts(productsData);

      // Extrair categorias únicas dos produtos
      const uniqueCategories = [
        ...new Set(productsData.map((product) => product.category)),
      ];
      setCategories(uniqueCategories);
    };

    fetchProducts();
  }, []);

  // Função para registrar uma visita
  const recordVisit = async () => {
    try {
      await addDoc(collection(db, "pageVisits"), {
        timestamp: Timestamp.now(),
      });
      console.log("Visita registrada");
    } catch (error) {
      console.error("Erro ao registrar visita: ", error);
    }
  };

  // Função para registrar usuário ativo
  const registerActiveUser = async () => {
    const userId = `user_${Date.now()}`;
    const userRef = doc(db, "activeUsers", userId);

    // Cria ou atualiza o documento do usuário ativo
    await setDoc(userRef, { lastActive: Timestamp.now() });

    // Atualiza o campo `lastActive` a cada 15 segundos
    const heartbeatInterval = setInterval(async () => {
      await setDoc(userRef, { lastActive: Timestamp.now() }, { merge: true });
    }, 15000);

    // Limpa o intervalo e remove o documento ao fechar
    window.addEventListener("beforeunload", async () => {
      clearInterval(heartbeatInterval);
      await deleteDoc(userRef);
    });
  };

  // Função para registrar cliques em ícones de redes sociais
  const recordSocialClick = async (buttonName) => {
    try {
      await addDoc(collection(db, "clickMetrics"), {
        button: buttonName,
        timestamp: Timestamp.now(),
      });
      console.log(`Clique registrado para o botão: ${buttonName}`);
    } catch (error) {
      console.error("Erro ao registrar clique: ", error);
    }
  };

  // Função para registrar cliques em produtos, com registros diários
  const recordProductClick = async () => {
    try {
      await addDoc(collection(db, "productMetrics"), {
        timestamp: Timestamp.now(),
        clicks: 1, // cada clique é registrado com um valor de 1
      });

      console.log("Clique registrado para um produto");
    } catch (error) {
      console.error("Erro ao registrar clique no produto: ", error);
    }
  };

  useEffect(() => {
    // Registrar uma visita sempre que a página é carregada
    recordVisit();

    // Registrar o usuário ativo
    registerActiveUser();

    AOS.init({
      duration: 1000,
      once: false,
      throttleDelay: 50,
    });
  }, []);

  const handleAddToCart = (product) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.name === product.name &&
          item.selectedSize === product.selectedSize
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.name === product.name &&
          item.selectedSize === product.selectedSize
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
    setIsCartOpen(true); // Abre o carrinho após adicionar o item
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <Router>
      <div className="app">
      <TextoRotativo />  
        <header className="header">
          <Link to="/inicio">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <nav className="nav">
            <ul className="menu">
              <li>
                <Link to="/inicio">Início</Link>
              </li>
              {categories.map((category) => (
                <li key={category}>
                  <Link to={`/${category.toLowerCase()}`}>{category}</Link>
                </li>
              ))}
            </ul>


            <div className="icons">
              <a
                href="https://wa.me/5538988154978"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => recordSocialClick("whatsapp")}
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://www.instagram.com/laiscoelholoja?igsh=ODJ2MXkxMWNqODUy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => recordSocialClick("instagram")}
              >
                <FaInstagram />
              </a>
              <Lupa products={products} />
              <button className="cart-icon" onClick={toggleCart}>
                <FaShoppingCart />
              </button>
              <Categorias categories={categories} />
            </div>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Navigate to="/inicio" />} />
          <Route path="/inicio" element={<Home products={products} 
             recordProductClick={recordProductClick}
             />} />
          <Route path="/meuspedidos" element={<Pedidos />} />
          {categories.map((category) => (
            <Route
              key={category}
              path={`/${category.toLowerCase()}`}
              element={
                <Category
                  products={products.filter(
                    (product) => product.category === category
                  )}
                />
              }
            />
          ))}
          {products.map((product) => (
            <Route
              key={product.id}
              path={`/${normalizeString(product.name)}`}
              element={
                <Produto
                  product={product}
                  onAddToCart={handleAddToCart}
                  products={products}
                /> // Passa 'products' aqui
              }
            />
          ))}
          <Route
            path="/checkout"
            element={<Checkout cartItems={cartItems} />}
          />
          {/* Rota catch-all para redirecionar URLs não definidas */}
          <Route path="*" element={<Navigate to="/inicio" />} />
        </Routes>
        {isCartOpen && <Carrinho items={cartItems} onClose={toggleCart} />}
        <Rodape />
      </div>
    </Router>
  );
}

function normalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .toLowerCase();
}

function Home({ products, recordProductClick }) {
  const [visibleProducts, setVisibleProducts] = useState(8);

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 8);
  };

  return (
    <div className="inicio">
      <main>
        {/* Primeiro banner com efeito de hover e AOS */}
        <img
          src={banner}
          alt="Banner"
          className="banner"
        
        />
 <h2 className="products-title">Destaques:</h2>
        <div className="product-list">
          {products.slice(0, visibleProducts).map((product, index) => (
            <div
              key={index}
              className="product-item"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <Link
                to={`/${normalizeString(product.name)}`}
                onClick={() => recordProductClick()}
              >
                <img
                  data-src={product.imageUrls[0]}
                  alt={product.name}
                  className="product-image lazyload"
                  src="placeholder.png"
                />
              </Link>
              <h3>{product.name}</h3>
              <div className="price-container">
                {product.discountPrice && (
                  <span className="old-price">
                    {formatPrice(product.discountPrice)}
                  </span>
                )}
                <span className="current-price">
                  {formatPrice(product.price)}
                </span>
              </div>
            </div>
          ))}
        </div>
        {visibleProducts < products.length && (
          <button className="load-more" onClick={loadMoreProducts}>
            Ver Mais
          </button>
        )}
        {/* Segundo banner com efeito de hover e AOS */}
        <img
          src={banner2}
          alt="Banner 2"
          className="banner2"
         
        />

        
        {/* Seções de informações com efeito de hover e AOS */}
        <div className="info-sections">
          <div
            className="info-item"
           
          >
            <FaShippingFast className="info-icon" />
            <h3>Pedido Rastreável</h3>
            <p>Todos os pedidos com rastreios 24H.</p>
          </div>
          <div
            className="info-item"
           
          >
            <FaHeadset className="info-icon" />
            <h3>Suporte ao Cliente</h3>
            <p>Suporte de segunda a sexta 09h - 18h.</p>
          </div>
          <div
            className="info-item"
           
          >
            <FaLock className="info-icon" />
            <h3>Pagamento seguro</h3>
            <p>Compras 100% seguras.</p>
          </div>
        </div>
      </main>
    </div>
  );
}

function Category({ products }) {
  return (
    <div className="category">
      <main>
        <h2 className="products-title">Produtos:</h2>
        <div className="product-list">
          {products.map((product, index) => (
            <div key={index} className="product-item">
              <Link to={`/${normalizeString(product.name)}`}>
                <img
                  data-src={product.imageUrls[0]}
                  alt={product.name}
                  className="product-image lazyload"
                  src="placeholder.png"
                />
              </Link>
              <h3>{product.name}</h3>
              <div className="price-container">
                {product.discountPrice && (
                  <span className="old-price">
                    {formatPrice(product.discountPrice)}
                  </span>
                )}
                <span className="current-price">
                  {formatPrice(product.price)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}
const formatPrice = (price) => {
  if (typeof price === "number") {
    return `R$ ${price
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  }
  return price;
};

export default App;
