// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDW_3OTcrZvnUbGvl0C71UxbKEJqn2TfzE",
  authDomain: "laiscoelholoja-2f076.firebaseapp.com",
  projectId: "laiscoelholoja-2f076",
  storageBucket: "laiscoelholoja-2f076.appspot.com",
  messagingSenderId: "989238046884",
  appId: "1:989238046884:web:45cd502ec0c91123baf631",
  measurementId: "G-JXBV6PB2KS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
