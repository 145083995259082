import React from "react";
import "./Rodape.css";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaInstagram,
  FaWhatsapp,
  FaLock,
} from "react-icons/fa";
import { SiPix } from "react-icons/si";
import { MdLocalShipping } from "react-icons/md";


const Rodape = () => {
  return (
    <footer className="rodape">
      <div className="rodape-pagamento-seguranca-envio">


        <div className="rodape-pagamento">
          <h3>Formas de Pagamento</h3>
          <div className="bandeiras-pagamento">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcAmex />
            <FaCcDiscover />
            <SiPix className="pix-icon" />
          </div>
        </div>

        
        
      <div className="rodape-social">
        <h3>UA Social</h3>
        <div className="social-icons">
          <a
           href="https://wa.me/5538988154978"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social-icon" />
            <span>WhatsApp</span>
          </a>
          <a
            href="https://www.instagram.com/laiscoelholoja?igsh=ODJ2MXkxMWNqODUy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
            <span>Instagram</span>
          </a>
        </div>
      </div>


      <div className="rodape-contato">
        <h3>Contato</h3>
        <p>
          <strong>E-mail:</strong> laiscoelho@gmail.com
          </p>
          <p>
          <strong>Telefone:</strong> (38)98815-4978
        </p>
        
      </div>

      <div className="rodape-info">
        <h3>CODE-X</h3>
        <p>2024 Lais Coelho - Todos os direitos reservados.</p>
        <p>© 2024 CODEX - Especialista em Lojas Virtuais</p>
      </div>
      </div>
  
    </footer>
  );
};

export default Rodape;
